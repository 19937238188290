<template>
  <div class="login-container">
    <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        class="login-form"
        label-position="top"
    >
      <div class="logo-img">
        <img :src="client_logo" alt/>
      </div>

      <h2 class="login-title">{{ $t("login.system") }}</h2>
      <el-form-item :label="$t('login.username')" prop="username">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.password')" prop="password">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item style="margin-top:28px;">
        <el-button type="primary" @click="submitForm('form')">{{ $t('login.button') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import user from "../model/user";
import tool from "../utils/tool";
import {defineComponent} from "vue";

export default defineComponent({
  name: "login",
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          {required: true, message: this.$t('login.user_empty'), trigger: "blur"},
          {min: 3, max: 10, message: this.$t('login.user_length'), trigger: "blur"}
        ],
        password: [
          {required: true, message: this.$t('login.password_empty'), trigger: "blur"},
          {min: 5, max: 20, message: this.$t('login.password_length'), trigger: "blur"}
        ]
      },
      client_logo: "",
      system: ''
    };
  },
  mounted() {
    let logo = tool.getLocalStorage("client_logo");
    if (logo === 'null') {
      this.client_logo = "https://adminapi.yztc2022.com/logo.png";
    } else {
      this.client_logo = logo;
    }
    this.system = tool.isEmpty(tool.getLocalStorage("client_name")) ? '智能手环管理系统' : tool.getLocalStorage("client_name")
  },
  methods: {
    async login() {
      const ret = await user.login({
        account: this.form.username,
        password: this.form.password
      });
      if (ret.code == 200) {
        // 将token写入cookie
        tool.saveLocalStorage("token", ret.data.token);
        tool.saveLocalStorage("uid", ret.data.uid);
        tool.saveLocalStorage("user_name", ret.data.name);
        tool.saveLocalStorage("client_logo", ret.data.client_logo);
        tool.saveLocalStorage("client_name", ret.data.client_name);
        localStorage.setItem("role", JSON.stringify(ret.data.role));
        localStorage.setItem("alarm", JSON.stringify(ret.data.alarm));
        this.$store.commit('SET_LOGIN_STATUS', 1)
        this.$router.push({
          path: "/"
        });
        // this.$router.back()
      } else {
        this.$message(ret.message);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.login();
        } else {
          console.log("验证失败");
          return false;
        }
      });
    }
  }
})
</script>
<style scoped>
.logo-img {
  text-align: center;
}

.logo-img img {
  width: auto;
  height: 90px;
  margin: 20px auto;
}

.login-title {
  margin-bottom: 30px;
}

.login-form {
  width: 400px;
  position: absolute;
  left: 45%;
  background: #fff;
  top: 18%;
  margin-left: -200px;
  border-radius: 10px;
  padding: 20px 50px 50px 50px;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-form-item /deep/ .el-form-item__label {
  padding-bottom: 0px !important;
  margin-top: 10px;
}

/* 背景 */
.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: cadetblue;
  /*background-image: url('../assets/img/bg.jpg');*/
  background-size: 100% 100%;
}

/* 标题 */
.login-title {
  color: #303133;
  text-align: center;
}

.el-button--primary {
  width: 100%;
}
</style>
